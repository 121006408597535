import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import Link from "@mui/material/Link";
import s from "./DeviceProcessingError.module.css";
import config from "../../config";

interface DeviceProcessingErrorProps {
  legacyDeviceError?: boolean;
}

export default function DeviceProcessingError(
  props: DeviceProcessingErrorProps,
) {
  const spaceUrl: string = config.gChatSupportSpace;
  const { legacyDeviceError } = props;
  const initialMessage: string = legacyDeviceError
    ? "Sorry, we can't provide data for the current device because it is a legacy device."
    : "Sorry, an error occurred during data processing";

  return (
    <Box className={s.processing_error_block}>
      <ReportGmailerrorredIcon
        fontSize="large"
        className={s.processing_error_icon}
      />
      <Typography id={s.processing_error_text}>
        {initialMessage} <br />
        If this information is important to you, please get in touch
        with <Link href={spaceUrl} target="_blank">#ncc-support</Link> in gChat for further
        assistance.
      </Typography>
    </Box>
  );
}
