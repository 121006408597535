import axios from "axios";
import axiosRetry from "axios-retry";
import config from "../config";
import { UserPublicDataType } from "../types/authTypes";
import { BuildingDataType } from "../types/buildingTypes";

class ApiInstanceClass {
    axiosInstance = axios.create({
        baseURL: config.apiUrl,
    });

    createAxiosInstance(token: string) {
      this.axiosInstance = axios.create({
        withCredentials: true,
        baseURL: config.apiUrl,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
    }

    getAxiosInstance() {
        return this.axiosInstance;
    }

    getUserInfo() {
        return ApiInstance.getAxiosInstance()
          .get<UserPublicDataType>('user/info')
          .then((r) => r.data);
    }

    getBuildings() {
      axiosRetry(ApiInstance.getAxiosInstance(), {
        retries: 2,
        retryDelay: (retryCount) => retryCount * 1500,
      });

      try {
        return ApiInstance.getAxiosInstance()
          .get<BuildingDataType[]>('buildings/')
          .then((r) => r.data);
      } finally {
        axiosRetry(ApiInstance.getAxiosInstance(), { retries: 0 });
      }
    }

    getBuildingDevices(building: string | undefined, region: string | null) {
      axiosRetry(ApiInstance.getAxiosInstance(), {
        retries: 2,
        retryDelay: (retryCount) => retryCount * 1500,
      });

      try {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices', {
            params: { building, region },
          })
          .then((r) => r.data);
      } finally {
        axiosRetry(ApiInstance.getAxiosInstance(), { retries: 0 });
      }
    }

    getCoreDevices(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/core', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data);
    }

    getDistroARP(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/distro/arp', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data);
    }

    getDistroStack(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/distro/stack', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data);
    }

    getSwitchPorts(
        ip_address: string | undefined,
        device_type: string | null,
        region: string | null,
        unit: string | null,
    ) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/switch/switch-ports', {
          params: { ip_address, device_type, region, unit },
          })
          .then((r) => r.data);
    }

    getSwitchStack(ip_address: string | undefined, device_type: string | null, region: string | null) {
        return ApiInstance.getAxiosInstance()
          .get('buildings/devices/switch/stack', {
          params: { ip_address, device_type, region },
          })
          .then((r) => r.data);
    }
}

export const ApiInstance = new ApiInstanceClass();
