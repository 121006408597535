import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import StackTable from '../modules/distro/StackTable';
import Typography from '@mui/material/Typography';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';
import SwitchPortsTable from "../modules/distro/SwitchPortsTable";

function AccessSwitchesPage() {
    const { selectedRegion } = useRegionQueryParam();

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                          Access switch manager - Region: {selectedRegion}
                        </Typography>
                        <StackTable
                            device={"switch"}
                        />
                        <SwitchPortsTable
                            device={"switch"}
                        />
                    </Box>
                }
        </>
    )
}


export default AccessSwitchesPage;
