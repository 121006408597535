import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import { ApiInstance } from "../../../api/api";
import { SwitchPortsDataContext } from "../../../context/switchPortsDataContext";
import {
  DeviceSwitchPortsDataType,
  DistroNameDataType,
} from "../../../types/buildingTypes";
import SwitchPortsSearch from "../SwitchPortsSearch";
import { useParams } from "react-router-dom";
import useRegionQueryParam from "../../../hooks/useRegionQueryParam";
import useSiteQueryParam from "../../../hooks/useSiteQueryParam";
import TablePlaceholder from "../../placeholders/TablePlaceholder";
import DeviceProcessingError from "../../placeholders/DeviceProcessingError";
import useVendorQueryParam from "../../../hooks/useVendorQueryParam";
import SwitchPortsFilterMenu, {
  DEFAULT_SWITCH_PORTS_FILTER_STATE,
} from "../../filters/SwitchPortsFilterMenu";
import { formatMacAddress } from "../../../utils/mac_formatter";
import MacDataDialog from "../../placeholders/MacDataDialog";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import { useSearchParams } from "react-router-dom";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadSwitchPortsCell {
  disablePadding: boolean;
  id: keyof DeviceSwitchPortsDataType;
  label: string;
  numeric: boolean;
}

const headSwitchPortsCells: readonly HeadSwitchPortsCell[] = [
  {
    id: "port",
    numeric: false,
    disablePadding: false,
    label: "Port",
  },
  {
    id: "vlan",
    numeric: false,
    disablePadding: false,
    label: "VLAN",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "poe",
    numeric: false,
    disablePadding: false,
    label: "POE",
  },
  {
    id: "speed",
    numeric: false,
    disablePadding: false,
    label: "Speed",
  },
  {
    id: "uptime",
    numeric: false,
    disablePadding: false,
    label: "Uptime",
  },
  {
    id: "description",
    numeric: false,
    disablePadding: false,
    label: "Description",
  },
  {
    id: "mac_address",
    numeric: false,
    disablePadding: false,
    label: "Mac address",
  },
];

interface SwitchPortsTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceSwitchPortsDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function SwitchPortsTableHead(props: SwitchPortsTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof DeviceSwitchPortsDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headSwitchPortsCells.map((HeadSwitchPortsCell) => (
          <TableCell
            key={HeadSwitchPortsCell.id}
            align={HeadSwitchPortsCell.numeric ? "right" : "left"}
            padding={HeadSwitchPortsCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadSwitchPortsCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadSwitchPortsCell.id}
              direction={orderBy === HeadSwitchPortsCell.id ? order : "asc"}
              onClick={createSortHandler(HeadSwitchPortsCell.id)}
            >
              {HeadSwitchPortsCell.label}
              {orderBy === HeadSwitchPortsCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  entity: string;
  search: boolean;
  navigationFilter?: boolean;
  navigationFilterName?: string;
  navigationFilterValue?: string;
}

function TableToolbar(props: TableToolbarProps) {
  const {
    title,
    entity,
    search,
    navigationFilter,
    navigationFilterName,
    navigationFilterValue,
  } = props;
  const [filterInput, setFilterInput] = React.useState(
    DEFAULT_SWITCH_PORTS_FILTER_STATE,
  );

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}: {entity}
      </Typography>
      {search && <SwitchPortsSearch />}
      <SwitchPortsFilterMenu
        filterState={filterInput}
        setFilterState={setFilterInput}
        navigationFilter={navigationFilter}
        navigationFilterName={navigationFilterName}
        navigationFilterValue={navigationFilterValue}
      />
    </Toolbar>
  );
}

interface SwitchPortsTypeTableProps {
  device: string;
}

interface FilterMap {
  [key: string]: string;
}

export default function SwitchPortsTable(props: SwitchPortsTypeTableProps) {
  const { device } = props;
  const { distro, access_switch } = useParams<DistroNameDataType>();
  const { selectedRegion } = useRegionQueryParam();
  const { selectedVendor } = useVendorQueryParam();
  const { selectedSite } = useSiteQueryParam();
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof DeviceSwitchPortsDataType>("port");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [allSwitchPorts, setAllSwitchPorts] = React.useState<
    DeviceSwitchPortsDataType[]
  >([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [switchPorts, setSwitchPorts] = React.useState<
    DeviceSwitchPortsDataType[]
  >([]);
  const [apiErrorFlag, setApiErrorFlag] = React.useState<Boolean>(false);
  const [loadingState, setLoadingState] = React.useState<Boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [macData, setMacData] = React.useState([]);
  const [selectedPort, setSelectedPort] = React.useState<string>("");
  const [searchParams] = useSearchParams();
  const [navigationFilter, setNavigation] = React.useState(false);
  const [navigationFilterName, setNavigationFilterName] =
    React.useState<string>("");
  const [navigationFilterValue, setNavigationFilterValue] =
    React.useState<string>("");

  const filterMap: FilterMap = {
    "filter[port]": "port",
    "filter[mac_address]": "mac_address",
  };

  const extractNavigationFilterParams = (
    searchParams: URLSearchParams,
    filterMap: FilterMap,
  ) => {
    for (const [key, value] of Array.from(searchParams.entries())) {
      if (key.startsWith("filter") && filterMap[key]) {
        return {
          navigationFilter: true,
          navigationFilterName: filterMap[key],
          navigationFilterValue: value,
        };
      }
    }

    return {
      navigationFilter: false,
      navigationFilterName: "",
      navigationFilterValue: "",
    };
  };

  const getSwitchPorts = async () => {
    setIsLoading(true);
    const { navigationFilter, navigationFilterName, navigationFilterValue } =
      extractNavigationFilterParams(searchParams, filterMap);

    if (device === "distro") {
      try {
        const distro_switch_ports = await ApiInstance.getSwitchPorts(
          distro,
          selectedVendor,
          selectedRegion,
          device,
        );
        setAllSwitchPorts(distro_switch_ports.switch_ports_data);
        setSwitchPorts(distro_switch_ports.switch_ports_data);
        setNavigation(navigationFilter);
        setNavigationFilterName(navigationFilterName);
        setNavigationFilterValue(navigationFilterValue);
      } catch (e: any) {
        setApiErrorFlag(true);
      }
    } else {
      try {
        const switch_stack = await ApiInstance.getSwitchPorts(
          access_switch,
          selectedVendor,
          selectedRegion,
          device,
        );
        setAllSwitchPorts(switch_stack.switch_ports_data);
        setSwitchPorts(switch_stack.switch_ports_data);
        setNavigation(navigationFilter);
        setNavigationFilterName(navigationFilterName);
        setNavigationFilterValue(navigationFilterValue);
      } catch (e: any) {
        setApiErrorFlag(true);
      }
    }

    setIsLoading(false);
  };

  React.useEffect(() => {
    getSwitchPorts();
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof DeviceSwitchPortsDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openMacDataDialog = (data: any, portName: string) => {
    setMacData(data);
    setSelectedPort(portName);
    setOpen(true);
  };

  const closeMacDataDialog = () => {
    setOpen(false);
    setMacData([]);
    setSelectedPort("");
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - switchPorts.length) : 0;

  const switchPortsRows = React.useMemo(
    () =>
      stableSort(switchPorts, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, switchPorts],
  );

  const column_labels = headSwitchPortsCells.map((item) => item.label);

  const renderMacAddresses = (mac_addresses: string) =>
    Object.values(mac_addresses).map((mac_address) => (
      <Typography variant="body2">{formatMacAddress(mac_address)}</Typography>
    ));

  return (
    <SwitchPortsDataContext.Provider
      value={{
        allSwitchPorts,
        switchPorts,
        loadingState,
        setSwitchPorts,
        setLoadingState,
      }}
    >
      <Box sx={{ width: "100%", margin: "5pt" }} id={"switch-ports"}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <TableToolbar
            title={`Switch ports for selected ${device}`}
            entity={`${selectedSite}`}
            search={true}
            navigationFilter={navigationFilter}
            navigationFilterName={navigationFilterName}
            navigationFilterValue={navigationFilterValue}
          />
          {isLoading ? (
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <TablePlaceholder rows={10} columns={column_labels} />
            </Box>
          ) : (
            <TableContainer sx={{ minHeight: "30rem" }}>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? "small" : "medium"}
              >
                <SwitchPortsTableHead
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                />
                <TableBody>
                  {switchPortsRows.map((row, index) => {
                    const labelId = `switch-ports-table-checkbox-${index}`;

                    return (
                      <TableRow hover sx={{ cursor: "pointer" }}>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="normal"
                        >
                          {row.port}
                        </TableCell>
                        <TableCell>{row.vlan}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.poe}</TableCell>
                        <TableCell>{row.speed}</TableCell>
                        <TableCell>{row.uptime}</TableCell>
                        <TableCell>{row.description}</TableCell>
                        {(() => {
                          if (row.mac_address.length > 1) {
                            return (
                              <TableCell>
                                <Button
                                  variant="outlined"
                                  endIcon={
                                    <DynamicFeedIcon fontSize={"large"} />
                                  }
                                  onClick={() =>
                                    openMacDataDialog(row.mac_address, row.port)
                                  }
                                >
                                  multiple
                                </Button>
                              </TableCell>
                            );
                          } else {
                            return (
                              <TableCell>
                                {renderMacAddresses(row.mac_address)}
                              </TableCell>
                            );
                          }
                        })()}
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (dense ? 33 : 53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                  {apiErrorFlag && (
                    <TableRow>
                      <TableCell colSpan={8}>
                        <DeviceProcessingError />
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          )}
          <TablePagination
            rowsPerPageOptions={[
              10,
              20,
              30,
              { value: switchPorts.length, label: "All" },
            ]}
            component="div"
            count={switchPorts.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per table:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
      <MacDataDialog
        open={open}
        handleClose={closeMacDataDialog}
        portName={selectedPort}
        macData={macData}
      />
    </SwitchPortsDataContext.Provider>
  );
}
