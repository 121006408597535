import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import StackTable from '../modules/distro/StackTable';
import SwitchPortsTable from "../modules/distro/SwitchPortsTable";
import {useParams} from 'react-router-dom';
import {DistroNameDataType} from '../../types/buildingTypes';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';


function DistroDevicesPage() {
    const { distro } = useParams<DistroNameDataType>();
    const { selectedRegion } = useRegionQueryParam();

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                            <Tooltip
                                title="ARP/DHCP table is not available for the selected distro!"
                            >
                                <InfoOutlinedIcon fontSize="medium" color="warning" />
                            </Tooltip>
                            Distro manager - IP: {distro}, Region: {selectedRegion}
                        </Typography>
                        <StackTable
                            device={"distro"}
                        />
                        <SwitchPortsTable
                            device={"distro"}
                        />
                    </Box>
                }
        </>
    )
}


export default DistroDevicesPage;
