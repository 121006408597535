import React from 'react';
import Header from '../modules/Header';
import {Box} from '@mui/material';
import ARPTable from '../modules/distro/ARPTable';
import StackTable from '../modules/distro/StackTable';
import Typography from '@mui/material/Typography';
import {useParams} from 'react-router-dom';
import {DistroNameDataType} from '../../types/buildingTypes';
import useRegionQueryParam from '../../hooks/useRegionQueryParam';
import SwitchPortsTable from "../modules/distro/SwitchPortsTable";


function DistroDHCPDevicesPage() {
    const { distro } = useParams<DistroNameDataType>();
    const { selectedRegion } = useRegionQueryParam();

    return (
        <>
            <Header/>
                {
                    <Box sx={{margin: 'auto', width: '80%'}}>
                        <Typography
                          sx={{ flex: '1 1 100%' }}
                          component="h1"
                          variant="h4"
                          align="center"
                          margin={3}
                        >
                          Distro manager - IP: {distro}, Region: {selectedRegion}
                        </Typography>
                        <ARPTable/>
                        <StackTable
                            device={"distro"}
                        />
                        <SwitchPortsTable
                            device={"distro"}
                        />
                    </Box>
                }
        </>
    )
}


export default DistroDHCPDevicesPage;
