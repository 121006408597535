import { useARPEntriesDataContext } from "../../context/arpEntriesDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DeviceARPDataType } from "../../types/buildingTypes";
import { formatMacAddress } from "../../utils/mac_formatter";

export default function ARPEntriesSearch() {
  const { allARPEntries, setARPEntries } = useARPEntriesDataContext();

  const handleSearchInput = (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    function filterByARPEntry(
      objects: DeviceARPDataType[],
      searchString: string,
    ) {
      return objects.filter(
        (obj) =>
          formatMacAddress(obj.mac_address.toLowerCase()).includes(
            formatMacAddress(searchString),
          ) ||
          obj.ip_address.toLowerCase().includes(searchString) ||
          obj.interface.toLowerCase().includes(searchString) ||
          obj.vlan.toLowerCase().includes(searchString) ||
          obj.dhcp_static.toLowerCase().includes(searchString),
      );
    }

    if (searchValue === "") {
      setARPEntries(allARPEntries);
    } else {
      const filteredArray = filterByARPEntry(allARPEntries, searchValue);
      setARPEntries(filteredArray);
    }
  };

  return (
    <TextField
      label="Search..."
      size="small"
      sx={{ width: "40ch", margin: "5pt", height: "30pt" }}
      onChange={handleSearchInput}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
