import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { useARPEntriesDataContext } from "../../context/arpEntriesDataContext";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import filterStyles from "./filterStyles";
import { formatMacAddress } from "../../utils/mac_formatter";
import { CoreDevicesFilterDataType } from "../../types/filterTypes";

export const DEFAULT_CORE_DEVICES_FILTER_STATE = {
  mac_address: "",
  ip_address: "",
  interface: "",
  vlan: "",
  dhcp_static: "",
  dhcp_reservation: "",
  dhcp_state: "",
};

interface CoreDevicesFilterMenuProps {
  filterState: any;
  setFilterState: any;
  navigationFilter?: boolean;
  navigationFilterName?: string;
  navigationFilterValue?: string;
}

export default function CoreDevicesFilterMenu(
  props: CoreDevicesFilterMenuProps,
) {
  const {
    filterState,
    setFilterState,
    navigationFilter,
    navigationFilterName,
    navigationFilterValue,
  } = props;
  const { allARPEntries, setARPEntries } = useARPEntriesDataContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [badge, setBadge] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);

  const resetFilters = () =>
    setFilterState({ ...DEFAULT_CORE_DEVICES_FILTER_STATE });

  const hasFilterData = (filter_data: CoreDevicesFilterDataType): boolean =>
    Object.values(filter_data).some((item) => item.trim() !== "");

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filter = () => {
    return allARPEntries.filter((obj) => {
      const matchesFilter = (key: string | number) => {
        const filterValue = filterState[key].trim();
        // @ts-ignore
        if (filterValue === "" || obj[key] == null) {
          return filterValue === "";
        }

        if (key === "mac_address") {
          return formatMacAddress(obj[key])
            .toLowerCase()
            .includes(formatMacAddress(filterValue).toLowerCase());
        } else {
          // @ts-ignore
          return obj[key].toLowerCase().includes(filterValue.toLowerCase());
        }
      };

      return (
        matchesFilter("mac_address") &&
        matchesFilter("ip_address") &&
        matchesFilter("interface") &&
        matchesFilter("vlan") &&
        matchesFilter("dhcp_static") &&
        matchesFilter("dhcp_reservation") &&
        matchesFilter("dhcp_state")
      );
    });
  };

  const handleFilterInput = (filterName: string) => (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    let tempFilterState = filterState;
    tempFilterState[filterName] = searchValue;
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setARPEntries(filteredArray);
  };

  const handleNavigationFilter = (
    filterName: string | undefined,
    filterValue: string | undefined,
  ) => {
    let tempFilterState = filterState;
    // @ts-ignore
    tempFilterState[filterName] = filterValue;
    setFilterState(tempFilterState);
    setBadge(true);

    const filteredArray = filter();
    setARPEntries(filteredArray);
  };

  const handleClearFilter = (filterName: string) => (e: any) => {
    let tempFilterState = filterState;
    tempFilterState[filterName] = "";
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setARPEntries(filteredArray);
  };

  const handleClearAllFilters = (e: any) => {
    setARPEntries(allARPEntries);
    resetFilters();
    setBadge(false);
  };

  React.useEffect(() => {
    resetFilters();

    if (navigationFilter && navigationFilterValue) {
      handleNavigationFilter(navigationFilterName, navigationFilterValue);
    }
  }, [navigationFilter]);

  return (
    <React.Fragment>
      <Tooltip title="Apply filters">
        <IconButton
          onClick={handleClickFilter}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {badge ? (
            <Badge variant="dot" color="primary">
              <FilterListIcon color="primary" fontSize={"large"} />
            </Badge>
          ) : (
            <FilterListIcon
              color={open ? "primary" : "action"}
              fontSize={"large"}
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Clear filters">
        <IconButton size="small" onClick={handleClearAllFilters}>
          <FilterListOffOutlinedIcon
            color={badge ? "primary" : "action"}
            fontSize={"large"}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleCloseFilter}
        slotProps={{
          paper: {
            sx: { ...filterStyles.menu },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ display: "flex" }}>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleClearAllFilters}
          >
            <FilterListOffOutlinedIcon fontSize={"small"} /> Clear
          </Button>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleCloseFilter}
          >
            Close
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["mac_address"]
                ? "Mac address:" + filterState["mac_address"]
                : "Mac address"
            }
            value={filterState["mac_address"] ? filterState["mac_address"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("mac_address")}
          />
          <IconButton onClick={handleClearFilter("mac_address")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["ip_address"]
                ? "IP address:" + filterState["ip_address"]
                : "IP address"
            }
            value={filterState["ip_address"] ? filterState["ip_address"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("ip_address")}
          />
          <IconButton onClick={handleClearFilter("ip_address")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["interface"]
                ? "Interface:" + filterState["interface"]
                : "Interface"
            }
            value={filterState["interface"] ? filterState["interface"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("interface")}
          />
          <IconButton onClick={handleClearFilter("interface")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["vlan"] ? "VLAN:" + filterState["vlan"] : "VLAN"}
            value={filterState["vlan"] ? filterState["vlan"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("vlan")}
          />
          <IconButton onClick={handleClearFilter("vlan")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["dhcp_static"]
                ? "DHCP/static:" + filterState["dhcp_static"]
                : "DHCP/static"
            }
            value={filterState["dhcp_static"] ? filterState["dhcp_static"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("dhcp_static")}
          />
          <IconButton onClick={handleClearFilter("dhcp_static")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["dhcp_reservation"]
                ? "DHCP reservation:" + filterState["dhcp_reservation"]
                : "DHCP reservation"
            }
            value={
              filterState["dhcp_reservation"]
                ? filterState["dhcp_reservation"]
                : ""
            }
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("dhcp_reservation")}
          />
          <IconButton onClick={handleClearFilter("dhcp_reservation")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["dhcp_state"]
                ? "DHCP state:" + filterState["dhcp_state"]
                : "DHCP state"
            }
            value={filterState["dhcp_state"] ? filterState["dhcp_state"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("dhcp_state")}
          />
          <IconButton onClick={handleClearFilter("dhcp_state")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
