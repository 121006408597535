import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { useBuildingDataContext } from "../../context/buildingDataContext";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import filterStyles from "./filterStyles";
import { BuildingFilterDataType } from "../../types/filterTypes";

export const DEFAULT_BUILDINGS_FILTER_STATE = {
  code: "",
  newcode: "",
  city: "",
  territory: "",
  region: "",
};

export default function BuildingsFilterMenu(props: any) {
  const { filterState, setFilterState } = props;
  const { allBuildings, setBuildings } = useBuildingDataContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [badge, setBadge] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);

  const resetFilters = () =>
    setFilterState({ ...DEFAULT_BUILDINGS_FILTER_STATE });

  const hasFilterData = (filter_data: BuildingFilterDataType): boolean =>
    Object.values(filter_data).some((item) => item.trim() !== "");

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filter = () => {
    return allBuildings.filter((obj) => {
      const matchesFilter = (key: string | number) => {
        const filterValue = filterState[key].trim();
        // @ts-ignore
        if (filterValue === "" || obj[key] == null) {
          return filterValue === "";
        }
        // @ts-ignore
        return obj[key].toLowerCase().includes(filterValue.toLowerCase());
      };

      return (
        matchesFilter("code") &&
        matchesFilter("newcode") &&
        matchesFilter("city") &&
        matchesFilter("territory") &&
        matchesFilter("region")
      );
    });
  };

  const handleFilterInput = (filterName: string) => (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    let tempFilterState = filterState;
    tempFilterState[filterName] = searchValue;
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setBuildings(filteredArray);
  };

  const handleClearFilter = (filterName: string) => (e: any) => {
    let tempFilterState = filterState;
    tempFilterState[filterName] = "";
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setBuildings(filteredArray);
  };

  const handleClearAllFilters = (e: any) => {
    setBuildings(allBuildings);
    resetFilters();
    setBadge(false);
  };

  React.useEffect(() => {
    resetFilters();
  }, []);

  return (
    <React.Fragment>
      <Tooltip title="Apply filters">
        <IconButton
          onClick={handleClickFilter}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {badge ? (
            <Badge variant="dot" color="primary">
              <FilterListIcon color="primary" fontSize={"large"} />
            </Badge>
          ) : (
            <FilterListIcon
              color={open ? "primary" : "action"}
              fontSize={"large"}
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Clear filters">
        <IconButton size="small" onClick={handleClearAllFilters}>
          <FilterListOffOutlinedIcon
            color={badge ? "primary" : "action"}
            fontSize={"large"}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleCloseFilter}
        slotProps={{
          paper: {
            sx: { ...filterStyles.menu },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ display: "flex" }}>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleClearAllFilters}
          >
            <FilterListOffOutlinedIcon fontSize={"small"} /> Clear
          </Button>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleCloseFilter}
          >
            Close
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["code"] ? "Code:" + filterState["code"] : "Code"}
            value={filterState["code"] ? filterState["code"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("code")}
          />
          <IconButton onClick={handleClearFilter("code")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["newcode"]
                ? "New code:" + filterState["newcode"]
                : "New code"
            }
            value={filterState["newcode"] ? filterState["newcode"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("newcode")}
          />
          <IconButton onClick={handleClearFilter("newcode")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["city"] ? "City:" + filterState["city"] : "City"}
            value={filterState["city"] ? filterState["city"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("city")}
          />
          <IconButton onClick={handleClearFilter("city")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["territory"]
                ? "Territory:" + filterState["territory"]
                : "Territory"
            }
            value={filterState["territory"] ? filterState["territory"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("territory")}
          />
          <IconButton onClick={handleClearFilter("territory")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["region"]
                ? "Region:" + filterState["region"]
                : "Region"
            }
            value={filterState["region"] ? filterState["region"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("region")}
          />
          <IconButton onClick={handleClearFilter("region")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
