type configType = {
    apiUrl: string;
    auth0Domain: string;
    auth0Client: string;
    auth0Audience: string;
    gChatSupportSpace: string;
};

const config: configType = {
    apiUrl:
        process.env.REACT_APP_API_URL ||
        'service_api_url',
    auth0Domain:
        process.env.REACT_APP_AUTH0_DOMAIN ||
        'service_api_auth_domain',
    auth0Client:
        process.env.REACT_APP_AUTH0_CLIENT_ID ||
        'service_api_auth0_client',
    auth0Audience:
        process.env.REACT_APP_AUTH0_AUDIENCE ||
        'service_api_auth0_audience',
    gChatSupportSpace:
        process.env.REACT_APP_NCC_GCHAT_SUPPORT_SPACE ||
        'gchat_support_space',
};

export default config;
