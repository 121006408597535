import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import {
  BuildingDevicesStructureDataType,
  BuildingNameDataType,
} from "../../types/buildingTypes";
import DevicesHostsSearch from "./DevicesHostsSearch";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Link as RouterLink, useParams } from "react-router-dom";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";
import useRegionQueryParam from "../../hooks/useRegionQueryParam";
import DeviceDataGettingError from "../placeholders/DeviceDataGettingError";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCoreCell {
  disablePadding: boolean;
  id: keyof BuildingDevicesStructureDataType;
  label: string;
  numeric: boolean;
}

const headCoreCells: readonly HeadCoreCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "ip_address",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "role",
    numeric: false,
    disablePadding: false,
    label: "Role",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
];

interface CoreTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesStructureDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function CoreTableHead(props: CoreTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof BuildingDevicesStructureDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCoreCells.map((HeadCoreCell) => (
          <TableCell
            key={HeadCoreCell.id}
            align={HeadCoreCell.numeric ? "right" : "left"}
            padding={HeadCoreCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadCoreCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadCoreCell.id}
              direction={orderBy === HeadCoreCell.id ? order : "asc"}
              onClick={createSortHandler(HeadCoreCell.id)}
            >
              {HeadCoreCell.label}
              {orderBy === HeadCoreCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  search: boolean;
}

function TableToolbar(props: TableToolbarProps) {
  const { title, search } = props;

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}:
      </Typography>
      {search && <DevicesHostsSearch />}
    </Toolbar>
  );
}

interface BuildingDevicesCoreTableProps {
  cores: BuildingDevicesStructureDataType[] | [];
  isLoading: boolean;
  apiErrorFlag: boolean;
}

export default function BuildingDevicesCoreTable(
  props: BuildingDevicesCoreTableProps,
) {
  const { cores, isLoading, apiErrorFlag } = props;
  const { building } = useParams<BuildingNameDataType>();
  const { selectedRegion } = useRegionQueryParam();
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BuildingDevicesStructureDataType>("ip_address");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesStructureDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - cores.length) : 0;

  const coreRows = React.useMemo(
    () =>
      stableSort(cores, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, cores],
  );

  const core_column_labels = headCoreCells.map((item) => item.label);

  return (
    <Box sx={{ width: "100%", margin: "5pt" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar title={"Core"} search={false} />
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TablePlaceholder rows={1} columns={core_column_labels} />
          </Box>
        ) : (
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <CoreTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {coreRows.map((row, index) => {
                  const labelId = `core-table-checkbox-${index}`;

                  return (
                    <TableRow hover sx={{ cursor: "pointer" }}>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.name}
                      </TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <TableCell>{row.ip_address}</TableCell>
                      <TableCell>{row.role}</TableCell>
                      <TableCell>
                        <Tooltip
                          describeChild
                          title={
                            <span style={{ fontSize: "18px" }}>
                              Connection to: {row.ip_address}
                            </span>
                          }
                          arrow
                          sx={{ fontSize: 20 }}
                        >
                          <Button
                            component={RouterLink}
                            to={`/devices/core/${row.ip_address}?region=${selectedRegion}&building=${building}`}
                            variant="outlined"
                            endIcon={
                              <AdsClickOutlinedIcon fontSize={"large"} />
                            }
                          >
                            {row.status === 1 ? "UP" : "DOWN"}
                          </Button>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <DeviceDataGettingError entity={"core"} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[
            5,
            10,
            15,
            { value: cores.length, label: "All" },
          ]}
          component="div"
          count={cores.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per table:"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
