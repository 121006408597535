import { useBuildingDataContext } from "../../context/buildingDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { BuildingDataType } from "../../types/buildingTypes";

export default function BuildingSearch() {
  const { allBuildings, setBuildings } = useBuildingDataContext();

  const handleSearchInput = (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    function filterByBuildingEntry(objects: BuildingDataType[], searchString: string) {
      return objects.filter(
        (obj) =>
          obj.building.toLowerCase().includes(searchString) ||
          (obj.city?.toLowerCase().includes(searchString) ?? false) ||
          obj.code.toLowerCase().includes(searchString) ||
          obj.newcode.toLowerCase().includes(searchString) ||
          obj.oldcode.toLowerCase().includes(searchString) ||
          (obj.spaceman_code?.toLowerCase().includes(searchString) ?? false) ||
          obj.region.toLowerCase().includes(searchString) ||
          obj.territory.toLowerCase().includes(searchString),
      );
    }

    if (searchValue === "") {
      setBuildings(allBuildings);
    } else {
      const filteredArray = filterByBuildingEntry(allBuildings, searchValue);
      setBuildings(filteredArray);
    }
  };

  return (
    <TextField
      label="Search..."
      size="small"
      sx={{ width: "40ch", margin: "5pt", height: "30pt" }}
      onChange={handleSearchInput}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
