import { useSwitchPortsDataContext } from "../../context/switchPortsDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { DeviceSwitchPortsDataType } from "../../types/buildingTypes";
import { formatMacAddress } from "../../utils/mac_formatter";

export default function SwitchPortsSearch() {
  const { allSwitchPorts, setSwitchPorts } = useSwitchPortsDataContext();

  const handleSearchInput = (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    function filterBySwitchPortEntry(
      objects: DeviceSwitchPortsDataType[],
      searchString: string,
    ) {
      return objects.filter(
        (obj) =>
          obj.port.toLowerCase().includes(searchString) ||
          obj.vlan.toLowerCase().includes(searchString) ||
          obj.status.toLowerCase().includes(searchString) ||
          (obj?.poe.toLowerCase().includes(searchString) ?? false) ||
          obj.speed.toLowerCase().includes(searchString) ||
          (obj?.uptime.toLowerCase().includes(searchString) ?? false) ||
          (obj.description.toLowerCase().includes(searchString) ?? false) ||
          //@ts-ignore
          formatMacAddress(obj.mac_address.join(" ").toLowerCase()).includes(
            formatMacAddress(searchString),
          ),
      );
    }

    if (searchValue === "") {
      setSwitchPorts(allSwitchPorts);
    } else {
      const filteredArray = filterBySwitchPortEntry(
        allSwitchPorts,
        searchValue,
      );
      setSwitchPorts(filteredArray);
    }
  };

  return (
    <TextField
      label="Search..."
      size="small"
      sx={{ width: "40ch", margin: "5pt", height: "30pt" }}
      onChange={handleSearchInput}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
