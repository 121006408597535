import React from "react";
import FeedbackOutlinedIcon from "@mui/icons-material/FeedbackOutlined";
import Button from "@mui/material/Button";

const FeedbackBlock: React.FC = () => {
  const SendFeedbackClick = async () => {
    window.open("https://forms.gle/2w4usBqqnJvRt2id7");
  };

  return (
    <Button
      size="small"
      variant="outlined"
      color="inherit"
      onClick={SendFeedbackClick}
      endIcon={<FeedbackOutlinedIcon fontSize={"small"} />}
      sx={{ "& .MuiButton-endIcon": { marginLeft: "2px" } }}
    >
      Feedback
    </Button>
  );
};

export default FeedbackBlock;
