import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ReportGmailerrorredIcon from '@mui/icons-material/ReportGmailerrorred';
import Link from '@mui/material/Link';
import s from './DeviceProcessingError.module.css'
import config from '../../config'

interface BuildingProcessingErrorProps {
  entity: string;
}

export default function BuildingProcessingError(props: BuildingProcessingErrorProps) {
  const { entity } = props;
  const spaceUrl: string = config.gChatSupportSpace;

  return (
    <Box className={s.error_block}>
      <ReportGmailerrorredIcon fontSize="large" className={s.processing_error_icon} />
        <Typography id={s.processing_error_text}>
          Oops, something went wrong while fetching {entity}' data. Please try again later.<br/>
          If the issue persists, please get in touch with <Link href={spaceUrl} target="_blank">#ncc-support</Link> in
          gChat for further assistance.
        </Typography>
    </Box>
  );
}
