import * as React from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import Badge from "@mui/material/Badge";
import Button from "@mui/material/Button";
import { useSwitchPortsDataContext } from "../../context/switchPortsDataContext";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import CloseIcon from "@mui/icons-material/Close";
import FilterListOffOutlinedIcon from "@mui/icons-material/FilterListOffOutlined";
import filterStyles from "./filterStyles";
import { formatMacAddress } from "../../utils/mac_formatter";
import { SwitchPortFilterDataType } from "../../types/filterTypes";

export const DEFAULT_SWITCH_PORTS_FILTER_STATE = {
  port: "",
  mac_address: "",
  vlan: "",
  status: "",
  poe: "",
  speed: "",
  description: "",
};

interface SwitchPortsFilterMenuProps {
  filterState: any;
  setFilterState: any;
  navigationFilter?: boolean;
  navigationFilterName?: string;
  navigationFilterValue?: string;
}

export default function SwitchPortsFilterMenu(
  props: SwitchPortsFilterMenuProps,
) {
  const {
    filterState,
    setFilterState,
    navigationFilter,
    navigationFilterName,
    navigationFilterValue,
  } = props;
  const { allSwitchPorts, setSwitchPorts } = useSwitchPortsDataContext();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [badge, setBadge] = React.useState<Boolean>(false);
  const open = Boolean(anchorEl);

  const resetFilters = () =>
    setFilterState({ ...DEFAULT_SWITCH_PORTS_FILTER_STATE });

  const hasFilterData = (filter_data: SwitchPortFilterDataType): boolean =>
    Object.values(filter_data).some((item) => item.trim() !== "");

  const scrollToFilter = () => {
    const element = document.getElementById(
      "switch-ports",
    ) as HTMLElement | null;
    if (element) {
      element.scrollIntoView({
        behavior: "auto",
        block: "start",
      });
    }
  };

  const handleClickFilter = (event: React.MouseEvent<HTMLElement>) => {
    scrollToFilter();
    setAnchorEl(event.currentTarget);
    if (open) {
      setAnchorEl(null);
    }
  };

  const handleCloseFilter = () => {
    setAnchorEl(null);
  };

  const filter = () => {
    return allSwitchPorts.filter((obj) => {
      const matchesFilter = (key: string | number) => {
        const filterValue = filterState[key].trim();
        // @ts-ignore
        if (filterValue === "" || obj[key] == null) {
          return filterValue === "";
        }

        if (key === "mac_address") {
          // @ts-ignore
          return formatMacAddress(obj[key].join(" "))
            .toLowerCase()
            .includes(formatMacAddress(filterValue).toLowerCase());
        } else {
          // @ts-ignore
          return obj[key].toLowerCase().includes(filterValue.toLowerCase());
        }
      };

      return (
        matchesFilter("port") &&
        matchesFilter("mac_address") &&
        matchesFilter("vlan") &&
        matchesFilter("status") &&
        matchesFilter("poe") &&
        matchesFilter("speed") &&
        matchesFilter("description")
      );
    });
  };

  const handleFilterInput = (filterName: string) => (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    let tempFilterState = filterState;
    tempFilterState[filterName] = searchValue;
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setSwitchPorts(filteredArray);
  };

  const handleNavigationFilter = (
    filterName: string | undefined,
    filterValue: string | undefined,
  ) => {
    let tempFilterState = filterState;
    // @ts-ignore
    tempFilterState[filterName] = filterValue;
    setFilterState(tempFilterState);
    setBadge(true);

    const filteredArray = filter();
    setSwitchPorts(filteredArray);
  };

  const handleClearFilter = (filterName: string) => (e: any) => {
    let tempFilterState = filterState;
    tempFilterState[filterName] = "";
    setFilterState(tempFilterState);
    setBadge(hasFilterData(tempFilterState));

    const filteredArray = filter();
    setSwitchPorts(filteredArray);
  };

  const handleClearAllFilters = (e: any) => {
    setSwitchPorts(allSwitchPorts);
    resetFilters();
    setBadge(false);
  };

  React.useEffect(() => {
    resetFilters();

    if (navigationFilter && navigationFilterValue) {
      handleNavigationFilter(navigationFilterName, navigationFilterValue);
    }
  }, [navigationFilter]);

  return (
    <React.Fragment>
      <Tooltip title="Apply filters">
        <IconButton
          onClick={handleClickFilter}
          size="small"
          sx={{ ml: 2 }}
          aria-controls={open ? "filter-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          {badge ? (
            <Badge variant="dot" color="primary">
              <FilterListIcon color="primary" fontSize={"large"} />
            </Badge>
          ) : (
            <FilterListIcon
              color={open ? "primary" : "action"}
              fontSize={"large"}
            />
          )}
        </IconButton>
      </Tooltip>
      <Tooltip title="Clear filters">
        <IconButton size="small" onClick={handleClearAllFilters}>
          <FilterListOffOutlinedIcon
            color={badge ? "primary" : "action"}
            fontSize={"large"}
          />
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        id="filter-menu"
        open={open}
        onClose={handleCloseFilter}
        slotProps={{
          paper: {
            sx: { ...filterStyles.menu },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem sx={{ display: "flex" }}>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleClearAllFilters}
          >
            <FilterListOffOutlinedIcon fontSize={"small"} /> Clear
          </Button>
          <Button
            sx={{ ...filterStyles.actionButton }}
            variant="outlined"
            onClick={handleCloseFilter}
          >
            Close
          </Button>
        </MenuItem>
        <Divider />
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["port"] ? "Port:" + filterState["port"] : "Port"}
            value={filterState["port"] ? filterState["port"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("port")}
          />
          <IconButton onClick={handleClearFilter("port")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["mac_address"]
                ? "Mac address:" + filterState["mac_address"]
                : "Mac address"
            }
            value={filterState["mac_address"] ? filterState["mac_address"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("mac_address")}
          />
          <IconButton onClick={handleClearFilter("mac_address")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["vlan"] ? "VLAN:" + filterState["vlan"] : "VLAN"}
            value={filterState["vlan"] ? filterState["vlan"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("vlan")}
          />
          <IconButton onClick={handleClearFilter("vlan")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["status"]
                ? "Status:" + filterState["status"]
                : "Status"
            }
            value={filterState["status"] ? filterState["status"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("status")}
          />
          <IconButton onClick={handleClearFilter("status")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={filterState["poe"] ? "POE:" + filterState["poe"] : "POE"}
            value={filterState["poe"] ? filterState["poe"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("poe")}
          />
          <IconButton onClick={handleClearFilter("poe")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["speed"] ? "Speed:" + filterState["speed"] : "Speed"
            }
            value={filterState["speed"] ? filterState["speed"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("speed")}
          />
          <IconButton onClick={handleClearFilter("speed")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
        <MenuItem onKeyDown={(e) => e.stopPropagation()}>
          <TextField
            label={
              filterState["description"]
                ? "Description:" + filterState["description"]
                : "Description"
            }
            value={filterState["description"] ? filterState["description"] : ""}
            size="small"
            sx={{ ...filterStyles.textField }}
            onChange={handleFilterInput("description")}
          />
          <IconButton onClick={handleClearFilter("description")}>
            <CloseIcon />
          </IconButton>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}
