import { useDevicesHostsDataContext } from "../../context/devicesHostsDataContext";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import { BuildingDevicesHostDataType } from "../../types/buildingTypes";
import { formatMacAddress } from "../../utils/mac_formatter";

export default function DevicesHostsSearch() {
  const { allHosts, setHosts } = useDevicesHostsDataContext();

  const handleSearchInput = (e: any) => {
    e.preventDefault();
    const searchValue: string = e.target.value.toLowerCase();

    function filterByHostEntry(
      objects: BuildingDevicesHostDataType[],
      searchString: string,
    ) {
      return objects.filter(
        (obj) =>
          formatMacAddress(obj.mac.toLowerCase()).includes(
            formatMacAddress(searchString),
          ) ||
          obj.ip_address.toLowerCase().includes(searchString) ||
          (obj.switch_ip_address?.toLowerCase().includes(searchString) ??
            false) ||
          (obj.name?.toLowerCase().includes(searchString) ?? false) ||
          (obj.switch_name?.toLowerCase().includes(searchString) ?? false) ||
          (obj.vendor?.toLowerCase().includes(searchString) ?? false) ||
          (obj.device_type?.toLowerCase().includes(searchString) ?? false) ||
          (obj.model?.toLowerCase().includes(searchString) ?? false) ||
          (obj.serial?.toLowerCase().includes(searchString) ?? false) ||
          (obj.switch_port?.toLowerCase().includes(searchString) ?? false),
      );
    }

    if (searchValue === "") {
      setHosts(allHosts);
    } else {
      const filteredArray = filterByHostEntry(allHosts, searchValue);
      setHosts(filteredArray);
    }
  };

  return (
    <TextField
      label="Search..."
      size="small"
      sx={{ width: "40ch", margin: "5pt", height: "30pt" }}
      onChange={handleSearchInput}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
