import * as React from "react";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { formatMacAddress } from "../../utils/mac_formatter";
import FeedOutlinedIcon from "@mui/icons-material/FeedOutlined";

interface MacDataDialogProps {
  open: boolean;
  handleClose: () => void;
  portName: string;
  macData: string[];
}

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function MacDataDialog(props: MacDataDialogProps) {
  const { open, handleClose, portName, macData } = props;
  const midIndex = Math.ceil(macData.length / 2);
  const firstColumnData = macData.slice(0, midIndex);
  const secondColumnData = macData.slice(midIndex);

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="mac-data-dialog-title"
        open={open}
      >
        <DialogTitle
          sx={{ m: 0, p: 2 }}
          id="mac-data-dialog-title"
          display="flex"
          justifyContent="center"
          alignItems="center"
          textAlign="center"
          color="primary"
        >
          <FeedOutlinedIcon fontSize={"small"} />
          MAC data for port: {portName}
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
          })}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              paddingLeft: "3px",
              border: "1px solid #1976d2",
              borderRadius: "8px",
              width: "360px",
              margin: "2px auto",
            }}
          >
            <Grid container spacing={0}>
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {firstColumnData.map((item, index) => (
                  <Typography key={index} variant="body1">
                    {formatMacAddress(item)}
                  </Typography>
                ))}
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                sx={{
                  mx: 2,
                  height: "auto",
                  border: "0.5px solid #1976d2",
                }}
              />
              <Grid
                item
                xs={5}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                {secondColumnData.map((item, index) => (
                  <Typography key={index} variant="body1">
                    {formatMacAddress(item)}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </React.Fragment>
  );
}
