import * as React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { visuallyHidden } from '@mui/utils';
import {ApiInstance} from "../../api/api";
import {BuildingDataType} from "../../types/buildingTypes";
import {BuildingDataContext} from "../../context/buildingDataContext";
import BuildingSearch from "./BuildingSearch";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import BuildingProcessingError from "../placeholders/BuildingProcessingError";
import BuildingsFilterMenu, {
  DEFAULT_BUILDINGS_FILTER_STATE,
} from "../filters/BuildingsFilterMenu";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof BuildingDataType;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: 'region',
    numeric: false,
    disablePadding: false,
    label: 'Region',
  },
  {
    id: 'territory',
    numeric: false,
    disablePadding: false,
    label: 'Territory',
  },
  {
    id: 'city',
    numeric: false,
    disablePadding: false,
    label: 'City',
  },
  {
    id: 'oldcode',
    numeric: false,
    disablePadding: false,
    label: 'Old code',
  },
  {
    id: 'code',
    numeric: false,
    disablePadding: false,
    label: 'Code',
  },
  {
    id: 'newcode',
    numeric: false,
    disablePadding: false,
    label: 'New code',
  },
  {
    id: 'spaceman_code',
    numeric: false,
    disablePadding: false,
    label: 'Spaceman code',
  },
  {
    id: 'building',
    numeric: false,
    disablePadding: false,
    label: 'Building',
  },
];

interface BuildingsTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof BuildingDataType) => void;
  order: Order;
  orderBy: string;
}

function BuildingsTableHead(props: BuildingsTableProps) {
  const { order, orderBy, onRequestSort } =
    props;
  const createSortHandler =
    (property: keyof BuildingDataType) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

function BuildingsTableToolbar() {
  const [filterInput, setFilterInput] = React.useState(DEFAULT_BUILDINGS_FILTER_STATE);

  return (
    <Toolbar>
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h5"
          id="tableTitle"
          component="div"
        >
          List of buildings:
        </Typography>
        <BuildingSearch/>
        <BuildingsFilterMenu filterState={filterInput} setFilterState={setFilterInput}/>
    </Toolbar>
  );
}

export default function BuildingsTable() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof BuildingDataType>('region');
  const [selected, setSelected] = React.useState<BuildingDataType[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [allBuildings, setAllBuildings] = React.useState<BuildingDataType[]>([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [buildings, setBuildings] = React.useState<BuildingDataType[]>([]);
  const [apiErrorFlag, setApiErrorFlag] = React.useState<Boolean>(false);
  const [loadingState, setLoadingState] = React.useState<Boolean>(false);

  const getBuildings = async () => {
    try {
      setIsLoading(true);
      const buildingData = await ApiInstance.getBuildings();

      setAllBuildings(buildingData);
      setBuildings(buildingData);
      setIsLoading(false);
    } catch (e: any) {
      setIsLoading(false);
      setApiErrorFlag(true);
    }
  }

  React.useEffect(() => {
    getBuildings()
  }, []);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDataType,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: BuildingDataType) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: BuildingDataType[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: BuildingDataType) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - buildings.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(buildings, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, buildings],
  );

  const column_labels = headCells.map(item => item.label);

  return (
    <BuildingDataContext.Provider value={{allBuildings, buildings, selected, loadingState, setBuildings, setLoadingState}}>
      <Box sx={{ width: '100%', margin: '5pt'}}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          <BuildingsTableToolbar/>
          {isLoading ? <Box style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}>
                <TablePlaceholder rows={15} columns={column_labels} />
              </Box> :
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? 'small' : 'medium'}
            >
              <BuildingsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>

                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row);
                  const labelId = `buildings-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.newcode}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                    >
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="normal"
                      >
                        {row.region}
                      </TableCell>
                      <TableCell>{row.territory}</TableCell>
                      <TableCell>{row.city}</TableCell>
                      <TableCell>{row.oldcode}</TableCell>
                      <TableCell>{row.code}</TableCell>
                      <TableCell>{row.newcode}</TableCell>
                      <TableCell>{row.spaceman_code}</TableCell>
                      <TableCell>
                        <Button
                            component={RouterLink}
                            to={`/devices/${row.building}?region=${row.region}`}
                            variant="outlined"
                        >
                          {row.building}
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <BuildingProcessingError entity={'buildings'} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          }
          <TablePagination
            rowsPerPageOptions={[25, 50, 100, { value: buildings.length, label: 'All' }]}
            component="div"
            count={buildings.length}
            rowsPerPage={rowsPerPage}
            labelRowsPerPage="Rows per table:"
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        <FormControlLabel
          control={<Switch checked={dense} onChange={handleChangeDense} />}
          label="Dense padding"
        />
      </Box>
    </BuildingDataContext.Provider>
  );
}
