import * as React from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { visuallyHidden } from "@mui/utils";
import {
  BuildingDevicesHostDataType,
  BuildingDevicesStructureDataType,
  DHCPDevicesStructureDataType,
} from "../../types/buildingTypes";
import DevicesHostsSearch from "./DevicesHostsSearch";
import TablePlaceholder from "../placeholders/TablePlaceholder";
import BuildingDevicesFilterMenu, {
  DEFAULT_HOSTS_FILTER_STATE,
} from "../filters/BuildingDevicesFilterMenu";
import DeviceDataGettingError from "../placeholders/DeviceDataGettingError";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import { Link as RouterLink } from "react-router-dom";
import AdsClickOutlinedIcon from "@mui/icons-material/AdsClickOutlined";

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadHostsCell {
  disablePadding: boolean;
  id: keyof BuildingDevicesHostDataType;
  label: string;
  numeric: boolean;
}

const headHostsCells: readonly HeadHostsCell[] = [
  {
    id: "ip_address",
    numeric: false,
    disablePadding: false,
    label: "IP address",
  },
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
  },
  {
    id: "mac",
    numeric: false,
    disablePadding: false,
    label: "MAC address",
  },
  {
    id: "vendor",
    numeric: false,
    disablePadding: false,
    label: "Vendor",
  },
  {
    id: "device_type",
    numeric: false,
    disablePadding: false,
    label: "Device type",
  },
  {
    id: "model",
    numeric: false,
    disablePadding: false,
    label: "Model",
  },
  {
    id: "serial",
    numeric: false,
    disablePadding: false,
    label: "Serial",
  },
  {
    id: "switch_ip_address",
    numeric: false,
    disablePadding: false,
    label: "Switch ip address",
  },
  {
    id: "switch_port",
    numeric: false,
    disablePadding: false,
    label: "Switch port",
  },
  {
    id: "switch_name",
    numeric: false,
    disablePadding: false,
    label: "Switch name",
  },
  {
    id: "last_seen",
    numeric: false,
    disablePadding: false,
    label: "Last seen",
  },
];

interface HostsTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => void;
  order: Order;
  orderBy: string;
}

function HostsTableHead(props: HostsTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof BuildingDevicesHostDataType) =>
    (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headHostsCells.map((HeadHostsCell) => (
          <TableCell
            key={HeadHostsCell.id}
            align={HeadHostsCell.numeric ? "right" : "left"}
            padding={HeadHostsCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === HeadHostsCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === HeadHostsCell.id}
              direction={orderBy === HeadHostsCell.id ? order : "asc"}
              onClick={createSortHandler(HeadHostsCell.id)}
            >
              {HeadHostsCell.label}
              {orderBy === HeadHostsCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

interface TableToolbarProps {
  title: string;
  search: boolean;
}

function TableToolbar(props: TableToolbarProps) {
  const { title, search } = props;
  const [filterInput, setFilterInput] = React.useState(
    DEFAULT_HOSTS_FILTER_STATE,
  );

  return (
    <Toolbar>
      <Typography
        sx={{ flex: "1 1 100%" }}
        variant="h5"
        id="tableTitle"
        component="div"
      >
        {title}:
      </Typography>
      {search && <DevicesHostsSearch />}
      <BuildingDevicesFilterMenu
        filterState={filterInput}
        setFilterState={setFilterInput}
      />
    </Toolbar>
  );
}

interface BuildingDevicesHostsTableProps {
  hosts: BuildingDevicesHostDataType[] | [];
  cores: BuildingDevicesStructureDataType[] | [];
  distros: BuildingDevicesStructureDataType[] | [];
  accessSwitches: BuildingDevicesStructureDataType[] | [];
  selectedRegion: string | null;
  isLoading: boolean;
  apiErrorFlag: boolean;
}

export default function BuildingDevicesHostTable(
  props: BuildingDevicesHostsTableProps,
) {
  const {
    hosts,
    cores,
    distros,
    accessSwitches,
    selectedRegion,
    isLoading,
    apiErrorFlag,
  } = props;
  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof BuildingDevicesHostDataType>("ip_address");
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof BuildingDevicesHostDataType,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - hosts.length) : 0;

  const hostRows = React.useMemo(
    () =>
      stableSort(hosts, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage,
      ),
    [order, orderBy, page, rowsPerPage, hosts],
  );

  const combinedSwitches: BuildingDevicesStructureDataType[] = [
    ...distros.map((distro) => {
      const entity = distro.role === "DHCP" ? "distro-dhcp" : "distro";
      return {
        ...distro,
        entity: entity,
        query: `${entity}/${distro.ip_address}?vendor=${distro.vendor}&site=${distro.name}&region=${selectedRegion}`,
      };
    }),
    ...accessSwitches.map((accessSwitch) => ({
      ...accessSwitch,
      entity: "switch",
      query: `switch/${accessSwitch.ip_address}?vendor=${accessSwitch.vendor}&site=${accessSwitch.name}&region=${selectedRegion}`,
    })),
  ];

  const combinedDHCPSwitches: DHCPDevicesStructureDataType[] = [
    ...cores.map((core) => ({
      ...core,
      entity: "core",
      query: `core/${core.ip_address}?region=${selectedRegion}`,
    })),
    ...distros.map((dhcpDistro) => ({
      ...dhcpDistro,
      entity: "distro",
      query: `distro-dhcp/${dhcpDistro.ip_address}?vendor=${dhcpDistro.vendor}&site=${dhcpDistro.name}&region=${selectedRegion}`,
    })),
  ];

  const findSwitchByIP = (switchIP: string): any => {
    return combinedSwitches.find(
      (relatedSwitch) => relatedSwitch.ip_address === switchIP,
    );
  };

  const dhcpDevicesIds = new Set(
    combinedDHCPSwitches
      .filter((dhcpSwitch) => dhcpSwitch.role === "DHCP")
      .map((dhcpSwitch) => dhcpSwitch.ip_address),
  );

  const coreDevicesIds = new Set(
    combinedDHCPSwitches
      .filter((dhcpSwitch) => dhcpSwitch.entity === "core")
      .map((dhcpSwitch) => dhcpSwitch.ip_address),
  );

  const showDhcpButton = (hostIp: string): boolean => {
    return !(dhcpDevicesIds.has(hostIp) || coreDevicesIds.has(hostIp));
  };

  const host_column_labels = headHostsCells.map((item) => item.label);
  const currentDHCP = combinedDHCPSwitches.find(
    (group) => group.role === "DHCP",
  );

  return (
    <Box sx={{ width: "100%" }} id={"hosts"}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableToolbar title={"Hosts"} search={true} />
        {isLoading ? (
          <Box
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <TablePlaceholder rows={10} columns={host_column_labels} />
          </Box>
        ) : (
          <TableContainer sx={{ minHeight: "30rem" }}>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <HostsTableHead
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {hostRows.map((row, index) => {
                  const relatedSwitch = findSwitchByIP(row.switch_ip_address);
                  const entityPath = relatedSwitch?.query;
                  const entity = relatedSwitch?.entity;
                  const showIpButton = showDhcpButton(row.ip_address);
                  const dhcpPath = currentDHCP?.query;
                  const dhcpEntity = currentDHCP?.entity;

                  return (
                    <TableRow
                      hover
                      sx={{ cursor: "pointer", whiteSpace: "nowrap" }}
                    >
                      {showIpButton ? (
                        <TableCell>
                          <Tooltip
                            describeChild
                            title={
                              <span style={{ fontSize: "12px" }}>
                                Connection to {dhcpEntity} with IP:{" "}
                                {currentDHCP?.ip_address}
                              </span>
                            }
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -5],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${dhcpPath}&filter[ip_address]=${row.ip_address}`}
                              variant="outlined"
                              endIcon={
                                <AdsClickOutlinedIcon fontSize="small" />
                              }
                            >
                              {row.ip_address}
                            </Button>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell>{row.ip_address}</TableCell>
                      )}
                      <TableCell>{row.name}</TableCell>
                      <TableCell>
                        {row.mac}
                        {entityPath && relatedSwitch && (
                          <Tooltip
                            describeChild
                            title={
                              <span style={{ fontSize: "12px" }}>
                                Connection to {entity} with IP:{" "}
                                {row.switch_ip_address}
                              </span>
                            }
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -5],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${entityPath}&filter[mac_address]=${row.mac}`}
                              variant="outlined"
                              sx={{ marginLeft: 0.5, minWidth: "auto" }}
                            >
                              L2
                            </Button>
                          </Tooltip>
                        )}
                        {showIpButton && (
                          <Tooltip
                            describeChild
                            title={
                              <span style={{ fontSize: "12px" }}>
                                Connection to {dhcpEntity} with IP:{" "}
                                {currentDHCP?.ip_address}
                              </span>
                            }
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -5],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${dhcpPath}&filter[mac_address]=${row.mac}`}
                              variant="outlined"
                              sx={{ marginLeft: 0.5, minWidth: "auto" }}
                            >
                              L3
                            </Button>
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>{row.vendor}</TableCell>
                      <TableCell>{row.device_type}</TableCell>
                      <TableCell>{row.model}</TableCell>
                      <TableCell>{row.serial}</TableCell>
                      <TableCell>{row.switch_ip_address}</TableCell>
                      {entityPath && relatedSwitch ? (
                        <TableCell>
                          <Tooltip
                            describeChild
                            title={
                              <span style={{ fontSize: "12px" }}>
                                Connection to {entity} with IP:{" "}
                                {row.switch_ip_address}
                              </span>
                            }
                            arrow
                            PopperProps={{
                              modifiers: [
                                {
                                  name: "offset",
                                  options: {
                                    offset: [0, -5],
                                  },
                                },
                              ],
                            }}
                          >
                            <Button
                              component={RouterLink}
                              to={`/devices/${entityPath}&filter[port]=${row.switch_port}`}
                              variant="outlined"
                              endIcon={
                                <AdsClickOutlinedIcon fontSize="small" />
                              }
                            >
                              {row.switch_port}
                            </Button>
                          </Tooltip>
                        </TableCell>
                      ) : (
                        <TableCell>{row.switch_port}</TableCell>
                      )}
                      <TableCell>{row.switch_name}</TableCell>
                      <TableCell>
                        {new Date(row.last_seen).toLocaleString()}
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
                {apiErrorFlag && (
                  <TableRow>
                    <TableCell colSpan={10}>
                      <DeviceDataGettingError entity={"hosts"} />
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        )}
        <TablePagination
          rowsPerPageOptions={[
            10,
            20,
            30,
            { value: hosts.length, label: "All" },
          ]}
          component="div"
          count={hosts.length}
          rowsPerPage={rowsPerPage}
          labelRowsPerPage="Rows per table:"
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      />
    </Box>
  );
}
